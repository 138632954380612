'use strict'
require("babel-polyfill");
require("picturefill");
import inView from './in-view.js';
import 'jquery-inview'
// require("lodash");

// import Rellax from 'rellax';
// import './jquery.youtubebackground'
// import 'youtube-background-video'
// import "jquery-youtube-background";
// import "jquery.scrollbar";
import 'objectFitPolyfill';

// import 'magnific-popup'

// local referrer
// if(document.referrer.indexOf(location.hostname) !== -1){
//   document.getElementsByTagName('body')[0].classList.add('has_internal_referrer');
// }
// loaded
// window.addEventListener('load', () => {
//   document.getElementsByTagName('body')[0].classList.add('loaded');
// });

class Device{
  constructor(option){
    const defaults = Object.assign({
      breakpoint: 768
    }, option);
    this.breakpoint = defaults.breakpoint
    this.callback = {
      sp: function(){},
      pc: function(){}
    };
    window.addEventListener('resize', this.resize.bind(this));
    window.addEventListener('load', this.resize.bind(this));
  }
  resize(){
    this.is_sp = window.innerWidth < this.breakpoint;
  }
  set is_sp(sp){
    if(this._is_sp !== sp){
      this.callback[sp ? 'sp' : 'pc'].apply(this);
    }
    this._is_sp = sp;
  }
  get is_sp(){
    return this._is_sp;
  }
  on(handle, fn){
    this.callback[handle] = fn;
  }
}

class BackgroundVideo{
  constructor(){
    this.video = document.getElementById('background-video');
    this.watch();
    this.state = 0;
    this.video.load();
  }
  watch(){
    this.video.addEventListener('canplaythrough', (event) => {
      this.state += 1;
      this.checkState();
    });
  }
  checkState(){
    if(this.state === 2){
      this.ready();
    }
  }
  ready(){
    this.play();
    $(this.video).css('opacity', 1);
  }
  play(){
    this.video.play();
  }
  pause(){
    this.video.pause();
  }
  incrememntState(){
    this.state += 1;
    this.checkState();
  }
}

// window.onload = function(){
//   const objectfitImageItems = document.querySelector('.object-fit');
//   console.log(objectfitImageItems)
  // var videoEl = document.querySelector('video');
  objectFitPolyfill();
// }

$(() => {
  $('img').css('visibilitiy', 'visible');
  // $('#mv').css('visibilitiy', 'visible');
  // objectFitImages('video');


  const $window = $(window);
  const $body = $('body');

  $('#menu-btn').on('click', () => {
    $body.toggleClass('modal')
  });
  $('#menu-body').on('click', () => {
    // if($body.hasClass('modal')){
      $body.removeClass('modal');
    // }
  })
  // console.log(inView)


  // $body.addClass('fv');
  $('#mv').on('inview', (event, isInView) => {
    // console.log('mv on inview???')
    if(isInView){
      // console.log('inview')
      backgroundvideo.play();
      // $body.addClass('fv');
    }else{
      // console.log('not inview')
      // $body.removeClass('fv');
      backgroundvideo.pause();
    }
  });

  // $body.addClass('fv')
  const toggleMenucolorClass = () =>{
    const windowHeight = $window.height();
    const scrollTop = $window.scrollTop();
    // console.log(windowHeight, scrollTop);
    if(windowHeight < scrollTop){
      $body.addClass('scrolled')
    }else{
      $body.removeClass('scrolled')
    }
  }
  window.addEventListener('scroll', _.throttle(toggleMenucolorClass, 200));
  toggleMenucolorClass();



  // console.log(_)

	const backgroundvideo = new BackgroundVideo();
  // $('#index-service').on('inview', (event, isInView) => {
    // if(isInView){
    // }else{
      // backgroundvideo.pause();
    // }
  // });


  // console.log($('#mv'))
  // $('#mv').YTPlayer({
  //   fitToBackground: false,
  //   videoId: 'zlRGQjdI85M',
  //   pauseOnScroll: false,
  //   playerVars: {
  //     modestbranding: 0,
  //     autoplay: 1,
  //     controls: 1,
  //     showinfo: 0,
  //     wmode: 'transparent',
  //     branding: 0,
  //     rel: 0,
  //     autohide: 0,
  //     origin: window.location.origin
  //   }
  // });

  // $body.addClass('ready');




  // rellax
  let rellax;
  const device = new Device({
    breakpoint: 768
  });
  device.on('sp', function(){
    // $body.addClass('sp');
    backgroundvideo.pause();



    // if(rellax){
    //   rellax.destroy();
    // }
    // if($('.rellax').length){
    //   rellax = new Rellax('.rellax', {
    //     speed: -4,
    //   });
    // }
  })
  device.on('pc', function(){
    // $body.removeClass('sp');

    backgroundvideo.play();



    // if(rellax){
    //   rellax.destroy();
    // }
    // if($('.rellax').length){
    //   rellax = new Rellax('.rellax', {
    //     speed: -8,
    //   });
    // }
    // mvHeight.free();
    $body.removeClass('modal');
  })
  device.resize();
  // window.addEventListener('resize', () => {
  //   if(rellax){
  //     rellax.refresh();
  //     // rellax.destroy();
  //   }
  // })


  // viewport ------------------------------------------------------------
  // inView.offset(300);


  $window.on('scroll.once', () => {
    inView.threshold(0.2);
    inView('.inview')
      .on('enter', (el) => {
        $(el).addClass('is-inview');
      })
    $window.off('scroll.once');
  })


  // scroll to top ------------------------------------------------------------
  // $('#scroll_to_top').click(function(){
  //   $('body, html').animate({'scrollTop': 0}, {'easing': 'swing', 'duration': 300});
  //   return false;
  // });

  // smooth scroll ------------------------------------------------------------
  function scrollTo(top){
    $('html,body').animate({ scrollTop: top }, {'easing': 'swing', 'duration': 500});
  }
  function calcScrollTo($el){
    // const offset = $('#nav').outerHeight() || 100;
    // console.log($('#nav').height())
    // console.log($('#nav').outerHeight())
    if($el.length){
      var to = $el.offset().top
    }else{
      var to = 0;
    }
    return to;
    // return to - offset;
  }
  $('a[href ^= "#"]').click(function(e){
    const $this = $(this);
    const hash = $this.attr('href');
    console.log(hash)
    if(hash === '#') return;


    const $el = $(hash);
    scrollTo(calcScrollTo($el));

    if($this.hasClass('nohash')){
      e.preventDefault();
    }
    $body.removeClass('modal');
    e.stopPropagation();
  });


  // restore scroll position
  if(location.hash){
    const $target = $(location.hash);
    if($target.length){
      const targetTop = $target.offset().top;
      // const menuOffset = $('body').hasClass('sp') ? 60 : 100;
      $('body, html').scrollTop(targetTop);
    }
  }


});
